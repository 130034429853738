import { array } from "prop-types"
import ROUTES from "../../routes.js"

const {
  SALE_VIEW_DATA: SALE,
  RENT_VIEW_DATA: RENT,
  TEMPORARY_VIEW_DATA: TEMPORARY,
} = JSON.parse(process.env.extra_data || ROUTES)
export const getOperations = property => {
  if (property?.operations) {
    const operationsArray = []
    property?.operations.map(operation => {
      switch (operation.operation_type.toLowerCase()) {
        case 'temporario' : {
          operationsArray.push(TEMPORARY.name)
          break
        }
        case 'alquiler temporario' : {
          operationsArray.push(TEMPORARY.name)
          break
        }
        case "alquiler": {
          operationsArray.push(RENT.name)
          break
        }
        case "venta": {
          operationsArray.push(SALE.name)
          break
        }
      }
    })
    return operationsArray
  }
  return [""]
}

export const getType = property => {
  if (property?.type) {
    return property?.type.name
  }
  return ""
}

export const getEnvironment = property => {
  return property?.room_amount
}

export const getSurface = property => {
  let surface = 0
  if (property?.type) {
    if (
      property?.type.name.toLowerCase() === "terreno industrial" ||
      property?.type.name.toLowerCase() === "terreno comercial" ||
      property?.type.name.toLowerCase() === "finca" ||
      property?.type.name.toLowerCase() === "chacra" ||
      property?.type.name.toLowerCase() === "terreno" ||
      property?.type.name.toLowerCase() === "campo"
    ) {
      surface = (property?.surface_measurement.toLowerCase() === 'ha' ? property?.surface.toString().replace("0000",''):property?.surface) + " " +  (property?.surface_measurement.toLowerCase() === 'ha' ? 'Ha.' :property?.surface_measurement)
    } else {
      surface =
        property?.total_surface + " " + 
        (property?.total_surface_measurement || 'M2')
    }
  }
  if (surface === "0 M2" || surface === "0 HA" || surface === "0.00 M2" || surface === '0') {
    return null
  }

  return surface?.toString()?.replace(".00",'')
}

export const getRooms = propert => {
  return propert?.suite_amount
}

export const getToilets = propert => {
  return propert?.toilet_amount
}

export const getBathrooms = propert => {
  return propert?.bathroom_amount
}

export const getSurfaces = property => {
  const surfacesArray = {
    roofed: property?.roofed_surface.split(".")[0],
    unroofed: property?.unroofed_surface.split(".")[0],
    semiroofed_surface: property?.semiroofed_surface.split(".")[0],
    surface: (property?.surface_measurement.toLowerCase() === 'ha' ? property?.surface.toString().replace("0000",''):property?.surface).split(".")[0],
    total_surface: property?.total_surface.split(".")[0],
    measurement: property?.surface_measurement.split(".")[0],
  }
  return surfacesArray
}

export const getLocation = property => {
  if (property?.location) {
    return property?.location.name
  }
  return ""
}

export const getFakeAddres = property => {
  return property?.fake_address || property?.address
}

export const getRealAddress = property => {
  return property?.real_address || property?.address
}

export const getPrices = property => {
  if (property?.operations) {
    const pricesArray = []

    property?.operations.map(operation => {
      let price
      if (operation.prices[0]) {
        price = operation.prices[0]
      } else {
        price = operation.prices
      }
      const obj = {}
      obj.type = operation.operation_type
      ;(obj.is_less_than_previous_price = price.is_less_than_previous_price),
        (obj.period = price.period),
        (obj.period_number = price.period_number),
        (obj.previous_price = price.previous_price)
      obj.price = !property?.web_price ? "Precio a consultar" : price.price
      obj.currency = !property?.web_price ? " " : price.currency
      pricesArray.push(obj)
    })
    return pricesArray
  }
  return []
}

export const getTags = property => {
  if (property?.tags) {
    return property?.tags
  }
  return []
}

export const getCustomTags = property => {
  if (property?.custom_tags) {
    return property?.custom_tags
  }
  return []
}

export const hasGarage = property => {
  return property?.parking_lot_amount ? true : false
}

export const countGarage = property => {
  if (property?.parking_lot_amount) {
    if (property?.parking_lot_amount > 0) {
      return property?.parking_lot_amount
    }
    return "No informado"
  } else {
    return "No informado"
  }
}

export const makeLink = property => {
  return (
    "/propiedad/" +
    getOperations(property)[0].toLowerCase() +
    "/" +
    getFakeAddres(property)
      ?.replaceAll(" ", "-")
      ?.replaceAll("/", "-")
      .toLowerCase() +
    "/" +
    property?.id
  )
}

export const makeTextAmenities = property => {
  let array = []
  let text = ""
  // Ambientes
  if (property?.room_amount > 0) {
    text += property?.room_amount ? property?.room_amount + " ambiente" : ""
    text += property?.room_amount > 1 ? "s" : ""
    array.push(text)
  }
  // Dormitorios
  text = ""
  if (property?.suite_amount > 0) {
    text +=
      " " + property?.suite_amount ? property?.suite_amount + " dormitorio" : ""
    text += property?.suite_amount > 1 ? "s" : ""
    array.push(text)
  }

  return array
}

export const makeTextBathrooms = property => {
  let array = []
  let text = ""
  // Baños
  if (property?.bathroom_amount > 0) {
    text +=
      " " + property?.bathroom_amount ? property?.bathroom_amount + " baño" : ""
    text += property?.bathroom_amount > 1 ? "s" : ""
    array.push(text)
  }
  text = ""
  if (property?.toilet_amount > 0) {
    text +=
      " " + property?.toilet_amount ? property?.toilet_amount + " toilette" : ""
    text += property?.toilet_amount > 1 ? "s" : ""
    array.push(text)
  }
  return array
}

export const makeTextSurfaces = (property, short = false) => {
  let text = ""
  let array = []
  // Total - array index 0
  if (property?.total_surface > 0) {
    text +=
      " " + property?.total_surface > 0
        ? property?.total_surface.split(".")[0] +
          ` ${property.total_surface_measurement || 'M2'} ${short ? "" : "tot. construído"}`
        : ""
    array.push(text)
    text = ""
  }
  // Roofed - array index 1
  if (property?.roofed_surface > 0) {
    text +=
      property?.roofed_surface > 0
        ? property?.roofed_surface.split(".")[0] +
          ` ${property.roofed_surface_measurement || 'M2'} ${short ? "" : "cubiertos"}`
        : ""
    array.push(text)
    text = ""
  }
  // Unroofed - array index 2
  if (property?.unroofed_surface > 0) {
    text +=
      " " + property?.unroofed_surface > 0
        ? property?.unroofed_surface.split(".")[0] +
          ` ${property.unroofed_surface_measurement || 'M2'} ${short ? "" : "descubiertos"}`
        : ""
    array.push(text)
    text = ""
  }
  // Semiroofed - array index 3
  if (property?.semiroofed_surface > 0) {
    text +=
      " " + property?.semiroofed_surface > 0
        ? property?.semiroofed_surface.split(".")[0] +
          ` ${property.semiroofed_surface_measurement || 'M2'} ${short ? "" : "semicubiertos"}`
        : ""
    array.push(text)
    text = ""
  }
  // Terreno - array index 4
  if (property?.surface > 0) {
    text +=
      " " + property?.surface > 0
        ? (property?.surface_measurement.toLowerCase() === 'ha' ? property?.surface.toString().replace("0000",''):property?.surface).split(".")[0] + ` ${(property?.surface_measurement.toLowerCase() === 'ha' ? 'Ha.' :property?.surface_measurement)} ${short ? "" : "terreno"}`
        : ""
    array.push(text)
    text = ""
  }

  return array
}

export const makeTextTags = property => {
  let text = ""
  let array = []
  if (property?.tags) {
    let index = 0
    while (index < 4 && index < property?.tags.length) {
      text += property?.tags[index].name + ""
      index++
      array.push(text)
      text = ""
    }
  }
  return array
}

export const makeTextParking = property => {
  let text = ""
  if (property?.parking_lot_amount > 0) {
    text +=
      " " + property?.parking_lot_amount
        ? property?.parking_lot_amount + " cochera" 
        : ""
    text += property?.parking_lot_amount > 1 ? "s" : ""
    text += (property?.parking_lot_type !== '' && property?.parking_lot_type !== 'Sin especificar' && property?.parking_lot_type !== undefined  ? " " + property?.parking_lot_type?.split(" ")?.map((text) => text.toLowerCase() + (property?.parking_lot_amount > 1 ? "s" : "")) : '').replaceAll(","," ")
  }
  return text
}
export const makeTextAge = (property, tokkoKey) => {
  return tokkoKey && typeof tokkoKey === "string" && property?.age !== undefined
    ? property?.age === -1
      ? "En construcción"
      : property?.age === 0
      ? "A estrenar"
      : property?.age === 1
      ? property?.age + " año"
      : property?.age > 1
      ? property?.age + " años"
      : ''
    : property?.age
}

export const getBluePrints = property => {
  if (property?.photos) {
    return property?.photos.filter(photo => photo.is_blueprint === true)
  }
  return []
}

export const getTour = (property, all = false) => {
  if (property?.videos) {
    return property.videos.filter(
      video =>
        video.provider.toLowerCase() === "matterport" ||
        video.provider.toLowerCase() === "hauzd" ||
        video.provider.toLowerCase() === "360magictour" ||
        video.provider.toLowerCase() === "vista360" ||
        video.provider.toLowerCase() === "roundme" ||
        video.player_url.includes("ricoh") ||
        video.is_tour
    )
  }
  return []
}

export const getTours = property => {
  if (property?.videos) {
    return property?.videos.filter(
      video =>
        video.provider.toLowerCase() === "matterport" ||
        video.provider.toLowerCase() === "hauzd" ||
        video.provider.toLowerCase() === "360magictour" ||
        video.provider.toLowerCase() === "vista360" ||
        video.provider.toLowerCase() === "roundme" ||
        video.provider.toLowerCase() === "kuula" ||
        video.player_url.includes("ricoh") ||
        video.is_tour
    )
  }
  return []
}

export const getVideo = property => {
  if (property?.videos) {
    const list_videos = property.videos.filter(
      video =>
        video.provider.toLowerCase() === "youtube" ||
        video.provider.toLowerCase() === "vimeo"
    )
    return list_videos
  }
  return []
}

export const getLastVideo = property => {
  if (property?.videos) {
    const list_videos = property?.videos.filter(
      video =>
        video.provider.toLowerCase() === "youtube" ||
        video.provider.toLowerCase() === "vimeo"
    )
    return list_videos[list_videos.length - 1]
  }
  return []
}

export const getAge = property => {
  if (property?.age === -1) {
    return "En construcción"
  }
  if (property?.age === 0) {
    return "A estrenar"
  }
  return property?.age
    ? `${property?.age} ${property?.age > 1 ? "años" : "año"}`
    : "No informado"
}

export const hasBarbacue = property => {
  // Return random boolean
  if (property?.tags?.find(element => element.id === 35)) {
    return true
  }
  return false
  // 51, name: 'Pileta', type:
}

export const hasPool = property => {
  // Return random boolean
  if (property) {
    if (property?.tags?.find(element => element.id === 51)) {
      return true
    }
  }
  return false
}
export const hasTag = (property, tag_id) => {
  // Return  boolean
  if (property) {
    if (property?.tags?.find(element => element.id === tag_id)) {
      return true
    }
  }
  return false
}

export const isFeatured = () => {
  // Return random boolean
  return Math.random() < 0.1
}

export const getNameProducer = property => {
  return property?.producer?.name
}

export const getPictureProducer = property => {
  return property?.producer?.picture
}

export const getCellphoneProducer = property => {
  return property?.producer?.cellphone
}

export const getEmailProducer = property => {
  return property?.producer?.email
}

export const getShortOperation = operation => {
  switch (operation.toLowerCase()) {
    case "venta":
      return "Venta"
    case "alquiler":
      return "Alquiler"
    default:
      return "Temporario"
  }
}
