import axios from 'axios'
import {convertStringToTime, removeExpiratedActions} from '../helpers/helper.actions'

// constantes
const dataInicial = {
    actions:[],
    loading_actions:[],
}

// types
const LOADING_ACTIONS = "LOADING_ACTIONS";
const GET_ACTIONS = 'GET_ACTIONS'

// reducer
export default function actionsReducer(state = dataInicial, action){
    switch(action.type){
        case LOADING_ACTIONS :
             return {...state,loading_actions:true,};
        case GET_ACTIONS:
            return {
                ...state,
                actions : action.payload,
                loading_actions:false
        };
        default:
            return state
    }
}

// actions
export const getActionsAction = (env) => async (dispatch, getState) => {
    // if(getState().actions.loading_actions || !env?.APISERVER){return null}
    dispatch({type: LOADING_ACTIONS})
    try {
        // const res = await axios.get('https://staging.mediacore.app/api/push/acciones/1/activas/')
        const res = await axios.get(`${env.APISERVER}/push/acciones/${env.CLIENTID}/activas/`)
        const actions = res.data
        const expirated_out = removeExpiratedActions(actions)
        dispatch({
            type: GET_ACTIONS,
            payload: expirated_out
        })
    } catch (error) {
        console.log(error)
    }

}