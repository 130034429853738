import axios from "axios"
import { useState } from "react"
// constantes
import ROUTES from "../../routes.js"
import { adapt_property_data } from "../helpers/helper.mediacore.jsx"

const {
  SALE_VIEW_DATA: SALE,
  RENT_VIEW_DATA: RENT,
  TEMPORARY_VIEW_DATA: TEMPORARY,
} = JSON.parse(process.env.extra_data || ROUTES)

const dataInicial = {
  properties: [""], // properties with offset
  properties_home: [], // properties with offset
  similar_properties: [], // properties simliar
  fullProperties: [""], // properties without offset
  property: {}, // property in detail
  featured: [], // featured properties
  favorites: [], // favorites properties
  selected: 0,
  loading: false,
  fetching_properties: false,
  loading_full: false,
  loading_more: false,
  loading_customs: false,
  loading_total_filters: false,
  loaded_total_filters: false,
  loading_locatons: false,
  loading_dynamic: false,
  searchHome: {
    operation: "venta",
    location: [],
    type: [],
    address: "",
  },
  filters: {
    location: [],
    type: [],
    operation: "",
    environments: "",
    bedrooms: "",
    age: "",
    address: "",
    producer_id: "",
    price: {
      type: "",
      priceMin: "",
      priceMax: "",
      is_off: false,
    },
    surface: {
      type: "",
      surfaceMin: "",
      surfaceMax: "",
    },
    environments_types: [],
    generals: [],
    services: [],
    specials: [],
  },
  coordinates: [],
  polygonCoordinates: [],
  orders: {
    order: "desc",
    order_by: "id",
  },
  totalListFilters: {
    location: [],
    type: [],
    operation: [],
    environments: "",
    bedrooms: "",
    age: [],
    price: [],
    surface: [],
    environments_types: [],
    generals: [],
    services: [],
    specials: [],
  },
  listFiltersByOperation: {},
  listLocationLite: {},
  listFilters: {
    location: [],
    type: [],
    operation: [],
    environments: "",
    bedrooms: "",
    age: [],
    price: [],
    surface: [],
    environments_types: [],
    generals: [],
    services: [],
    specials: [],
  },
  listLocationFilters: {
    location: [],
    type: [],
    operation: [],
    environments: "",
    bedrooms: "",
    age: [],
    price: [],
    surface: [],
    environments_types: [],
    generals: [],
    services: [],
    specials: [],
  },
  listDynamicFilters: {
    location: [],
    type: [],
    operation: [],
    environments: "",
    bedrooms: "",
    age: [],
    price: [],
    surface: [],
    environments_types: [],
    generals: [],
    services: [],
    specials: [],
  },
  allLocationFilters: [],
  custom_tags: [],
  offset: 0,
  totalCount: 0,
  totalCountVenta: 0,
  totalCountAlquiler: 0,
  totalCountAlquilerTemporario: 0, // Ver esto,
  updaterFavorites: false,
  currentlyImage: 1, // Lightbox global
  currentlyBluePrint: 1, // Lightbox global
  togglerImages: false, // Lightbox global
  togglerVideos: false,
  togglerTours: false,
  togglerBlueprints: false,
}

// types
const LOADING_PROPERTIES = "LOADING_PROPERTIES"
const LOADING = "LOADING"
const FETCHING_PROPERTIES = "FETCHING_PROPERTIES"
const LOADING_CUSTOMS = "LOADING_CUSTOMS"
const LOADING_TOTAL_FILTERS = "LOADING_TOTAL_FILTERS"
const LOADING_FULL_PROPERTIES = "LOADING_FULL_PROPERTIES"
const LOADING_FEATURED = "LOADING_FEATURED"
const LOADING_MORE = "LOADING_MORE"
const LOADING_LOCATIONS = "LOADING_LOCATIONS"
const LOADING_COUNT = "LOADING_COUNT"
const LOADING_DYNAMIC = "LOADING_DYNAMIC"
const CHANGE_TYPE = "CHANGE_TYPE"
const UPDATE_OFFSET = "UPDATE_OFFSET"
const UPDATE_FILTERS = "UPDATE_FILTERS"
const UPDATE_ORDERS = "UPDATE_ORDERS"
const UPDATE_SELECTED = "UPDATE_SELECTED"
const UPDATE_COORDINATES = "UPDATE_COORDINATES"
const UPDATE_POLYGON_COORDINATES = "UPDATE_POLYGON_COORDINATES"
const DRAWN_POLYGON = "DRAWN_POLYGON"
const LIST_FILTERS = "LIST_FILTERS"
const LIST_FILTERS_BY_OPERATION = "LIST_FILTERS_BY_OPERATION"
const LIST_LOCATION_LITE_BY_TYPE = "LIST_LOCATION_LITE_BY_TYPE"
const TOTAL_LIST_FILTERS = "TOTAL_LIST_FILTERS"
const LIST_LOCATION_FILTERS = "LIST_LOCATION_FILTERS"
const LIST_DYNAMIC_FILTERS = "LIST_DYNAMIC_FILTERS"
const GET_CUSTOMS_TAGS = "GET_CUSTOMS_TAGS"
const GET_PROPERTIES_HOME = "GET_PROPERTIES_HOME"
const GET_PROPERTY = "GET_PROPERTY"
const GET_FAV_PROPERTY = "GET_FAV_PROPERTY"
const CLEAR_FAV_PROPERTY = "CLEAR_FAV_PROPERTY"
const DELETE_FAV_PROPERTY = "DELETE_FAV_PROPERTY"
const GET_PROPERTIES = "GET_PROPERTIES"
const GET_SIMILAR_PROPERTIES = "GET_SIMILAR_PROPERTIES"
const GET_FULL_PROPERTIES = "GET_FULL_PROPERTIES"
const GET_MORE_PROPERTIES = "GET_MORE_PROPERTIES"
const GET_FEATURED_PROPERTIES = "GET_FEATURED_PROPERTIES"
const CLEAR_PROPERTIES = "CLEAR_PROPERTIES"
const CLEAR_PROPERTY = "CLEAR_PROPERTY"

const UPDATER_FAVORITES = "UPDATER_FAVORITES"

const SET_SEARCH_HOME_OPERATION = "SET_SEARCH_HOME_OPERATION"
const SET_SEARCH_HOME_TYPE = "SET_SEARCH_HOME_TYPE"
const SET_SEARCH_HOME_LOCATION = "SET_SEARCH_HOME_LOCATION"
const SET_SEARCH_HOME_ADDRESS = "SET_SEARCH_HOME_ADDRESS"

const SET_CURRENTLY_BLUEPRINT = "SET_CURRENTLY_BLUEPRINT"
const SET_CURRENTLY_IMAGES = "SET_CURRENTLY_IMAGES"
const SET_TOGGLER_IMAGES = "SET_TOGGLER_IMAGES"
const SET_TOGGLER_VIDEOS = "SET_TOGGLER_VIDEOS"
const SET_TOGGLER_TOURS = "SET_TOGGLER_TOURS"
const SET_TOGGLER_BLUEPRINTS = "SET_TOGGLER_BLUEPRINTS"

const GET_TOTALCOUNT = "GET_TOTALCOUNT"
const TOTAL_COUNT_ALQUILER = "TOTAL_COUNT_ALQUILER"
const TOTAL_COUNT_VENTA = "TOTAL_COUNT_VENTA"
const TOTAL_COUNT_ALQTEMP = "TOTAL_COUNT_ALQTEMP"

const GET_ALL_LOCATION = "GET ALL_LOCATION"

// reducer
export default function propertiesReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING_PROPERTIES:
      return { ...state, loading: true }
    case LOADING:
      return { ...state, loading: true }
    case FETCHING_PROPERTIES:
      return { ...state, fetching_properties: true }
    case LOADING_COUNT:
      return { ...state, loading_count: true }
    case LOADING_FEATURED:
      return { ...state, loading: true }
    case LOADING_CUSTOMS:
      return { ...state, loading_customs: true }
    case LOADING_TOTAL_FILTERS:
      return { ...state, loading_total_filters: true }
    case LOADING_FULL_PROPERTIES:
      return { ...state, loading_full: true }
    case LOADING_MORE:
      return { ...state, loading_more: true }
    case LOADING_LOCATIONS:
      return { ...state, loading_locatons: true }
    case LOADING_DYNAMIC:
      return { ...state, loading_dynamic: true }
    case CHANGE_TYPE:
      return { ...state, listed: action.payload }
    case SET_CURRENTLY_BLUEPRINT:
      return { ...state, currentlyBluePrint: action.payload }
    case SET_CURRENTLY_IMAGES:
      return { ...state, currentlyImage: action.payload }
    case SET_TOGGLER_IMAGES:
      return { ...state, togglerImages: !state.togglerImages }
    case SET_TOGGLER_VIDEOS:
      return { ...state, togglerVideos: !state.togglerVideos }
    case SET_TOGGLER_TOURS:
      return { ...state, togglerTours: !state.togglerTours }
    case SET_TOGGLER_BLUEPRINTS:
      return { ...state, togglerBlueprints: !state.togglerBlueprints }
    case SET_SEARCH_HOME_OPERATION:
      return {
        ...state,
        searchHome: { ...state.searchHome, operation: action.payload },
      }
    case SET_SEARCH_HOME_TYPE:
      return {
        ...state,
        searchHome: { ...state.searchHome, type: action.payload },
      }
    case SET_SEARCH_HOME_ADDRESS:
      return {
        ...state,
        searchHome: { ...state.searchHome, address: action.payload },
      }
    case SET_SEARCH_HOME_LOCATION:
      return {
        ...state,
        searchHome: { ...state.searchHome, location: action.payload },
      }
    case UPDATE_FILTERS:
      return { ...state, filters: action.payload }
    case UPDATE_ORDERS:
      return { ...state, orders: action.payload }
    case UPDATE_OFFSET:
      return { ...state, offset: action.payload }
    case UPDATE_COORDINATES:
      return { ...state, coordinates: action.payload }
    case UPDATE_POLYGON_COORDINATES:
      return { ...state, polygonCoordinates: action.payload }
    case DRAWN_POLYGON:
      return { ...state, drawnPolygon: action.payload }
    case UPDATE_SELECTED:
      return { ...state, selected: action.payload }
    case TOTAL_LIST_FILTERS:
      return {
        ...state,
        totalListFilters: action.payload,
        loaded_total_filters: true,
        loading_total_filters: false,
      }
    case TOTAL_COUNT_VENTA:
      return { ...state, totalCountVenta: action.payload }
    case TOTAL_COUNT_ALQUILER:
      return { ...state, totalCountAlquiler: action.payload }
    case TOTAL_COUNT_ALQTEMP:
      return { ...state, totalCountAlquilerTemporario: action.payload }
    case GET_CUSTOMS_TAGS:
      return { ...state, custom_tags: action.payload, loading_customs: false }
    case LIST_FILTERS:
      return { ...state, listFilters: action.payload }
    case LIST_FILTERS_BY_OPERATION:
      return {
        ...state,
        listFiltersByOperation: action.payload,
        loading_total_filters: false,
      }
    case LIST_LOCATION_LITE_BY_TYPE:
      return {
        ...state,
        listLocationLite: action.payload,
        loading_total_filters: false,
      }
    case LIST_LOCATION_FILTERS:
      return {
        ...state,
        listLocationFilters: action.payload,
        loading_locatons: false,
      }
    case GET_ALL_LOCATION:
      return {
        ...state,
        allLocationFilters: action.payload,
        loading_locatons: false,
      }
    case LIST_DYNAMIC_FILTERS:
      return {
        ...state,
        listDynamicFilters: action.payload,
        loading_dynamic: false,
      }
    case GET_FULL_PROPERTIES:
      return {
        ...state,
        fullProperties: action.payload.data.objects,
        loading_full: false,
      }
    case GET_PROPERTY:
      return { ...state, property: action.payload.data, loading: false }
    case GET_FAV_PROPERTY:
      return {
        ...state,
        favorites: [...state.favorites, action.payload.data],
        loading: false,
      }
    case DELETE_FAV_PROPERTY:
      return {
        ...state,
        favorites: state.favorites.filter(
          element => element.id !== action.payload
        ),
        loading: false,
      }
    case CLEAR_FAV_PROPERTY:
      return { ...state, favorites: [], loading: false }
    case GET_PROPERTIES_HOME:
      return {
        ...state,
        properties_home: action.payload,
        fetching_properties: false,
      }
    case GET_PROPERTIES:
      return {
        ...state,
        properties: action.payload.data.objects,
        totalCount: action.payload.data.meta.total_count,
        loading: false,
      }
    case GET_SIMILAR_PROPERTIES:
      return {
        ...state,
        similar_properties: action.payload.data.objects,
        loading: false,
      }
    case GET_MORE_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
        offset: state.offset + 50,
        loading_more: false,
      }
    case GET_FEATURED_PROPERTIES:
      return { ...state, featured: action.payload, loading: false }
    case UPDATER_FAVORITES:
      return { ...state, updaterFavorites: !state.updaterFavorites }
    case CLEAR_PROPERTIES:
      return { ...state, properties: [], loading: false }
    case CLEAR_PROPERTY:
      return { ...state, property: {}, loading: false }
    default:
      return state
  }
}
// actions
export const changeTypeList = type => (dispatch, getState) => {
  dispatch({
    type: CHANGE_TYPE,
    payload: type,
  })
}
export const getTotalListFilters = () => async (dispatch, getState) => {
  // if(getState().properties.loading_total_filters) return null;
  dispatch({ type: LOADING_TOTAL_FILTERS })
  const summary_filters = JSON.stringify({
    current_localization_id: [],
    current_localization_type: "",
    // "currency":"",
    price_from: 0,
    price_to: 9999999999999,
    operation_types: [1, 2, 3],
    property_types: [],
  })
  try {
    const res = await axios.get(
      "https://tokkobroker.com/api/v1/property/get_search_summary/?key=" +
        getState().settings.keys.tokko +
        "&lang=es_ar&format=json&data=" +
        encodeURI(summary_filters)
    )

    dispatch({
      type: TOTAL_COUNT_VENTA,
      payload: res.data.objects.operation_types.find(
        element => element.operation_type === 1
      )?.count,
    })

    dispatch({
      type: TOTAL_COUNT_ALQUILER,
      payload: res.data.objects.operation_types.find(
        element => element.operation_type === 2
      )?.count,
    })
    dispatch({
      type: TOTAL_COUNT_ALQTEMP,
      payload: res.data.objects.operation_types.find(
        element => element.operation_type === 3
      )?.count,
    })

    dispatch({
      type: TOTAL_LIST_FILTERS,
      payload: res,
    })
  } catch (error) {
    console.log(error)
  }
}
export const getListFilters = () => async (dispatch, getState) => {
  const filters = getState().properties.filters
  const operation_id = [
    { name: SALE.route, id: 1 },
    { name: RENT.route, id: 2 },
    { name: TEMPORARY.route, id: 3 },
  ]
  const operation = filters.operation
    ? [
        operation_id?.find(
          element => element.name === filters.operation.toLowerCase()
        ).id,
      ]
    : []
  const types = filters.type ? filters.type.map(element => element.id) : []
  const locations = filters.location
    ? filters.location.map(element => element.id)
    : []
  const currency =
    filters.price.type === "dolares"
      ? "USD"
      : filters.price.type === "pesos"
      ? "ARS"
      : ""
  const price_from = filters.price.priceMin ? filters.price.priceMin : 0
  const price_to = filters.price.priceMax ? filters.price.priceMax : 9999999999
  if (operation) {
    const summary_filters = JSON.stringify({
      current_localization_id: [],
      current_localization_type: "",
      price_from: 0,
      price_to: 9999999999999,
      operation_types: operation,
      property_types: [],
    })
    try {
      const res = await axios.get(
        "https://tokkobroker.com/api/v1/property/get_search_summary/?key=" +
          getState().settings.keys.tokko +
          "&lang=es_ar&format=json&data=" +
          encodeURI(summary_filters)
      )
      dispatch({
        type: LIST_FILTERS,
        payload: res,
      })
    } catch (error) {
      console.log(error)
    }
  }
}
export const getListLocationsFilters = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_LOCATIONS })
  const filters = getState().properties.filters
  const operation_id = [
    { name: SALE.route, id: 1 },
    { name: RENT.route, id: 2 },
    { name: TEMPORARY.route, id: 3 },
  ]
  const operation = filters.operation
    ? [
        operation_id?.find(
          element => element.name === filters.operation.toLowerCase()
        ).id,
      ]
    : []
  const types = filters.type ? filters.type.map(element => element.id) : []
  if (operation) {
    const summary_filters = JSON.stringify({
      current_localization_id: [],
      current_localization_type: "",
      price_from: 0,
      price_to: 9999999999999,
      operation_types: operation,
      property_types: types,
    })
    try {
      const res = await axios.get(
        "https://tokkobroker.com/api/v1/property/get_search_summary/?key=" +
          getState().settings.keys.tokko +
          "&lang=es_ar&format=json&data=" +
          encodeURI(summary_filters)
      )
      dispatch({
        type: LIST_LOCATION_FILTERS,
        payload: res,
      })
    } catch (error) {
      console.log(error)
    }
  }
}
export const getListLocations =
  (custom_operation = 0, custom_types = []) =>
  async (dispatch, getState) => {
    const filters = getState().properties.filters
    const operation_id = [
      { name: SALE.route, id: 1 },
      { name: RENT.route, id: 2 },
      { name: TEMPORARY.route, id: 3 },
    ]
    let types = []
    const operation = filters.operation
      ? [
          operation_id?.find(
            element => element.name === filters.operation.toLowerCase()
          ).id,
        ]
      : []
    // if(custom_types === ''){
    //   types = filters.type ? filters.type.map(element => element.id) : [];
    // }
    // else{
    // }
    const total_filters = getState().properties.totalListFilters
    for (let i = 0; i < custom_types; i++) {
      const id_type =
        custom_types[i] !== ""
          ? [
              total_filters.data.objects.property_types.find(
                element =>
                  element?.type?.toLowerCase() ===
                  custom_types[i]?.toLowerCase()
              )?.id,
            ]
          : []
      types.push(id_type)
    }
    // types = custom_types ? id_type : [];
    const auxOperation = [custom_operation]
    const summary_filters = JSON.stringify({
      current_localization_id: [],
      current_localization_type: "",
      price_from: 0,
      price_to: 9999999999999,
      operation_types: custom_operation > 0 ? auxOperation : operation,
      property_types: types,
    })

    try {
      const res = await axios.get(
        "https://tokkobroker.com/api/v1/property/get_search_summary/?key=" +
          getState().settings.keys.tokko +
          "&lang=es_ar&format=json&data=" +
          encodeURI(summary_filters)
      )
      const totalParents =
        getState().properties.totalListFilters.data.objects.locations
      const resume_parents = res.data.objects.locations.filter(
        element => !element.parent_id
      )
      const aux_parents = []
      res.data.objects.locations.map(element => {
        if (
          totalParents.find(
            global =>
              global.location_id === element.parent_id ||
              global.location_name?.toLowerCase() ===
                element.parent_name?.toLowerCase()
          )
        ) {
          if (
            !resume_parents.find(
              parent =>
                parent.location_id === element.parent_id ||
                parent.location_id === element.location_id ||
                parent.location_name?.toLowerCase() ===
                  element.parent_name?.toLowerCase()
            )
          ) {
            resume_parents.push(
              totalParents.find(
                global =>
                  global.location_id === element.parent_id ||
                  global.location_name?.toLowerCase() ===
                    element.parent_name?.toLowerCase()
              )
            )
          }
          // if(!resume_parents.find(parent => parent.parent_id === element.parent_id && !parent.location_id === element.parent_id)){
          //   resume_parents.push(totalParents.find(parent => parent.parent_id === element.parent_id))
          // }
        } else {
          if (resume_parents.length === 0) {
            aux_parents.push(element)
          }
          // resume_parents.push(element)
        }
      })
      if (resume_parents.length === 0) {
        resume_parents.push(...aux_parents)
      }
      res.data.objects.locations = resume_parents
      // const resume_parents = res.data.objects.locations.filter(element => !element.parent_id)
      // res.data.objects.locations = resume_parents;
      dispatch({
        type: LIST_LOCATION_FILTERS,
        payload: res,
      })
    } catch (error) {
      console.log(error)
    }
  }
export const getListDynamicFilters = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_DYNAMIC })
  const filters = getState().properties.filters
  const operation_id = [
    { name: SALE.route, id: 1 },
    { name: RENT.route, id: 2 },
    { name: TEMPORARY.route, id: 3 },
  ]
  const operation = filters.operation
    ? [
        operation_id?.find(
          element => element.name === filters.operation.toLowerCase()
        ).id,
      ]
    : []
  const types = filters.type ? filters.type.map(element => element.id) : []
  const locations = filters.location
    ? filters.location.map(element => element.id)
    : []

  if (operation) {
    const summary_filters = JSON.stringify({
      current_localization_id: locations,
      current_localization_type: "division",
      price_from: 0,
      price_to: 9999999999999,
      operation_types: operation,
      property_types: types,
    })
    try {
      const res = await axios.get(
        "https://tokkobroker.com/api/v1/property/get_search_summary/?key=" +
          getState().settings.keys.tokko +
          "&lang=es_ar&format=json&data=" +
          encodeURI(summary_filters)
      )
      dispatch({
        type: LIST_DYNAMIC_FILTERS,
        payload: res,
      })
    } catch (error) {
      console.log(error)
    }
  }
}
export const getListLocationsAction =
  (custom_operation = "", custom_types = "") =>
  async (dispatch, getState) => {
    const operation_id = [
      { name: "Tipo de operación", id: "1,2,3" },
      { name: "tipo de operación", id: "1,2,3" },
      { name: "tipo-de-operación", id: "1,2,3" },
      { name: SALE.route, id: 1 },
      { name: RENT.route, id: 2 },
      { name: TEMPORARY.route, id: 3 },
    ]
    let types = []
    let params_get = ""
    if (custom_operation.length === 3) {
      params_get += "?operation=1,2,3"
    } else {
      params_get += "?operation=" + custom_operation
    }
    if (custom_types?.length > 0) {
      const total_filters = getState().properties.totalListFilters
      for (let i = 0; i < custom_types.length; i++) {
        console.log(custom_types)
        const id_type =
          custom_types[i] !== ""
            ? [
                total_filters.data.objects.property_types.find(
                  element =>
                    element?.type?.toLowerCase() ===
                    custom_types[i].name?.toLowerCase()
                )?.id,
              ]
            : []
        types.push(id_type)
      }
      params_get += "&tipo=" + types.join(", ")
    }
    try {
      const res = await axios.get(
        // https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/locations?operation=1&tipo=8
        "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/locations/" +
          params_get,
        {
          headers: {
            "X-TOKKO-KEY": getState().settings.keys.tokko,
          },
        }
      )

      let filter = [] // Filtro los repetidos

      for (let i = 0; i < res.data.objects.length; i++) {
        if (
          filter.find(
            element =>
              element.location_name.toLowerCase() ===
              res.data.objects[i].location_name.toLowerCase()
          )
        ) {
        } else {
          filter.push(res.data.objects[i])
        }
      }

      res.data.objects = filter
      dispatch({ type: GET_ALL_LOCATION, payload: res.data })
    } catch (error) {
      console.log(error)
    }
  }
export const getAllLocationsAction = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_LOCATIONS })
  const summary_filters = JSON.stringify({
    current_localization_id: [],
    current_localization_type: "",
    price_from: 0,
    price_to: 9999999999999,
  })
  try {
    const res = await axios.get(
      "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/locations" +
        "?lang=es_ar&format=json&data=" +
        encodeURI(summary_filters),
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko,
        },
      }
    )
    dispatch({ type: GET_ALL_LOCATION, payload: res.data })
  } catch (error) {
    console.log(error)
  }
}
export const getFiltersByOperation =
  (_operation = "venta") =>
  async (dispatch, getState) => {
    const filters = getState().properties.filters

    const operation_id = [
      { name: SALE.route, id: 1 },
      { name: RENT.route, id: 2 },
      { name: TEMPORARY.route, id: 3 },
    ]

    const operation = _operation
      ? [
          operation_id?.find(
            element => element.name === _operation.toLowerCase()
          ).id,
        ]
      : []

    if (operation) {
      const summary_filters = JSON.stringify({
        current_localization_id: [],
        current_localization_type: "",
        price_from: 0,
        price_to: 9999999999999,
        operation_types: operation,
        property_types: [],
      })
      try {
        const res = await axios.get(
          "https://tokkobroker.com/api/v1/property/get_search_summary/?key=" +
            getState().settings.keys.tokko +
            "&lang=es_ar&format=json&data=" +
            encodeURI(summary_filters)
        )
        dispatch({
          type: LIST_FILTERS_BY_OPERATION,
          payload: res,
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
export const getLocationsLiteByTypeAction =
  (_operation = "venta", custom_types = "") =>
  async (dispatch, getState) => {
    if (getState().properties.loading_total_filters) return null
    const total_filters = getState().properties.totalListFilters

    // console.log(total_filters.data.objects.property.types)
    const operation_id = [
      { name: SALE.route, id: 1 },
      { name: RENT.route, id: 2 },
      { name: TEMPORARY.route, id: 3 },
    ]
    const operation = _operation
      ? [
          operation_id?.find(
            element => element.name === _operation.toLowerCase()
          ).id,
        ]
      : []

    console.log(custom_types)
    if (operation) {
      let id_type
      if (Array.isArray(custom_types)) {
        id_type = custom_types.map(element => element.id)
      } else {
        id_type =
          custom_types !== ""
            ? [
                total_filters.data.objects.property_types.find(
                  element =>
                    element?.type?.toLowerCase() === custom_types?.toLowerCase()
                )?.id,
              ]
            : []
      }
      const types = custom_types ? id_type : []
      const summary_filters = JSON.stringify({
        current_localization_id: [],
        current_localization_type: "",
        price_from: 0,
        price_to: 9999999999999,
        operation_types: operation.length > 0 ? operation : [1, 2, 3],
        property_types: types,
      })
      dispatch({ type: LOADING_TOTAL_FILTERS })
      try {
        const res = await axios.get(
          "https://tokkobroker.com/api/v1/property/get_search_summary/?key=" +
            getState().settings.keys.tokko +
            "&lang=es_ar&format=json&data=" +
            encodeURI(summary_filters)
        )
        dispatch({
          type: LIST_LOCATION_LITE_BY_TYPE,
          payload: res,
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

export const getCustomTags = () => async (dispatch, getState) => {
  if (
    getState().properties.custom_tags.length > 0 ||
    getState().properties.loading_custom
  ) {
    return null
  }
  dispatch({
    type: LOADING_CUSTOMS,
  })
  try {
    const res = await axios.get(
      "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/custom-tags/summary",
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko,
        },
      }
    )
    dispatch({
      type: GET_CUSTOMS_TAGS,
      payload: res,
    })
  } catch (error) {
    console.log(error)
  }
}
// Filters
export const REDUX_UPDATE_COORDINATES = coordinates => (dispatch, getState) => {
  // if(coordinates.length > 0){
  //   let { filters } = getState().properties;
  //   filters.location = []
  //   REDUX_UPDATE_FILTERS(filters)
  // }
  dispatch({
    type: UPDATE_COORDINATES,
    payload: coordinates,
  })
}
export const REDUX_UPDATE_POLYGON_COORDINATES =
  coordinates => (dispatch, getState) => {
    // if(coordinates.length > 0){
    //   let { filters } = getState().properties;
    //   filters.location = []
    //   REDUX_UPDATE_FILTERS(filters)
    // }
    dispatch({
      type: UPDATE_POLYGON_COORDINATES,
      payload: coordinates,
    })
  }
export const REDUX_UPDATE_DRAWN_POLYGON = polygon => (dispatch, getState) => {
  dispatch({
    type: DRAWN_POLYGON,
    payload: polygon,
  })
}
export const REDUX_UPDATE_FILTERS =
  (_filters, toggleOperation = "") =>
  (dispatch, getState) => {
    if (toggleOperation) {
      if (toggleOperation === "ALQUILER TEMP.") {
        _filters.operation = "alquiler-temporario"
      } else {
        _filters.operation = toggleOperation
      }
    }
    if (_filters !== getState().properties.filters) {
      dispatch({ type: UPDATE_OFFSET, payload: 0 })
      dispatch({
        type: UPDATE_FILTERS,
        payload: _filters,
      })
    }
  }
export const redux_updateOrders = orders => (dispatch, getState) => {
  dispatch({
    type: UPDATE_ORDERS,
    payload: orders,
  })
}
// Properties
export const getPropertiesAction = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_PROPERTIES,
  })
  const filters = getState().properties.filters
  const orders = getState().properties.orders
  const coordinates = getState().properties.coordinates
  const polygon = getState().properties.polygonCoordinates
  var params_get = ""
  var tags_both = []
  var specials = []
  if (filters.operation.toString() === "") {
    return null
  }
  const operation_id = [
    { name: SALE.route, id: 1 },
    { name: RENT.route, id: 2 },
    { name: TEMPORARY.route, id: 3 },
  ]
  const environments = [
    { name: "1 ambiente", id: 1 },
    { name: "2 ambientes", id: 2 },
    { name: "3 ambientes", id: 3 },
    { name: "4 ambientes", id: 4 },
    { name: "5 ambientes", id: 5 },
    { name: "6 ambientes", id: 6 },
    { name: "7 ambientes", id: 7 },
    { name: "8 ambientes", id: 8 },
    { name: "mas de 9 ambientes", id: 9 },
  ]
  if (filters.operation) {
    params_get +=
      "&operation=" +
      operation_id?.find(
        element => element.name === filters.operation.toLowerCase()
      ).id
  }
  if (filters.type.length > 0) {
    params_get += "&tipo=" + filters.type.map(element => element.id)
  }
  if (filters.location.length > 0) {
    params_get +=
      "&localization-divisions=" +
      filters.location.map(element => element.id) +
      "&localization-type=division"
  }
  if (filters.services.length > 0) {
    tags_both.push(filters.services.map(element => element.id))
  }
  if (filters.specials.length > 0) {
    specials.push(filters.specials.map(element => element.id))
    params_get += "&custom_tags=" + specials.map(element => element)
  }
  if (filters.generals.length > 0) {
    tags_both.push(filters.generals.map(element => element.id))
  }
  if (filters.environments_types.length > 0) {
    tags_both.push(filters.environments_types.map(element => element.id))
  }
  if (
    filters.environments_types.length > 0 ||
    filters.generals.length ||
    filters.services.length > 0
  ) {
    params_get += "&tags=" + tags_both
  }
  if (filters.surface.type) {
    var type_surface = ""
    const surfaceMin = filters.surface.surfaceMin
      ? filters.surface.surfaceMin
      : 0
    const surfaceMax = filters.surface.surfaceMax
      ? filters.surface.surfaceMax
      : 999999999
    if (filters.surface.type.toLowerCase() == "cubierta") {
      type_surface = "roofed"
    } else {
      type_surface = filters.surface.type
    }
    params_get +=
      "&surface-type=" +
      type_surface +
      "&surface=" +
      surfaceMin +
      "-" +
      surfaceMax
  }
  if (filters.price.type) {
    var price_type = ""
    switch (filters.price.type) {
      case "pesos": {
        price_type = "ars"
        break
      }
      default:
        price_type = "usd"
    }

    const priceMin = filters.price.priceMin ? filters.price.priceMin : 0
    const priceMax = filters.price.priceMax ? filters.price.priceMax : 10000000

    params_get +=
      "&currency=" + price_type + "&price=" + priceMin + "-" + priceMax
  }
  if (filters.environments !== "") {
    const rooms_string = "&rooms=" + filters.environments
    params_get += rooms_string
  }
  if (filters.bedrooms !== "") {
    const suites_string = "&suites=" + filters.bedrooms
    params_get += suites_string
  }
  if (filters.age) {
    switch (filters.age.toLowerCase()) {
      case "en construccion": {
        params_get += "&age=-1"
        break
      }
      case "a estrenar": {
        params_get += "&age=0"
        break
      }
      case "1 a 5 años": {
        params_get += "&age=1-5"
        break
      }
      case "6 a 10 años": {
        params_get += "&age=6-10"
        break
      }
      case "11 a 20 años": {
        params_get += "&age=11-20"
        break
      }
      case "21 a 50 años": {
        params_get += "&age=21-50"
        break
      }
      case "+ 50 años": {
        params_get += "&age=50-99"
        break
      }
    }
  }
  if (coordinates?.length > 0) {
    params_get +=
      "&longitude=" +
      coordinates[0] +
      "," +
      coordinates[1] +
      "&latitude=" +
      coordinates[2] +
      "," +
      coordinates[3]
  }
  if (filters.price.is_off) {
    params_get += "&appraisal=decrease"
  }
  if (polygon) {
    let polygon_coordinates = []
    for (let index = 0; index < polygon.length; index++) {
      polygon_coordinates +=
        "&coordinates[" +
        index +
        "][lat]=" +
        polygon[index].lat +
        "&coordinates[" +
        index +
        "][lng]=" +
        polygon[index].lng
    }
    params_get += polygon_coordinates
  }
  if (filters.address) {
    params_get += "&fake_address=" + filters.address
  }
  try {
    const res = await axios.post(
      // "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/search?cache=1&save="+ (polygon.length > 0 ? "1" : "0") + "&limit=" + (polygon.length > 0 ? '50&chunk=1' : '50') + "&offset=" + getState().properties.offset + "&order-by=" +
      "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/search?cache=1&save=" +
        (polygon.length > 0 ? "1" : "0") +
        "&limit=" +
        (polygon.length > 0 ? "2000&chunk=1" : "50") +
        "&offset=" +
        getState().properties.offset +
        "&order-by=" +
        orders.order_by +
        "&order=" +
        orders.order +
        "",
      params_get,
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko,
        },
      }
    )
    dispatch({
      type: GET_PROPERTIES,
      payload: res,
    })
  } catch (error) {
    console.log(error)
  }
}
export const getSimilarPropertiesAction =
  property => async (dispatch, getState) => {
    if (property?.id) {
      dispatch({
        type: LOADING,
      })

      const operation_id = [
        { name: SALE.route, id: 1 },
        { name: RENT.route, id: 2 },
        { name: TEMPORARY.route, id: 3 },
      ]

      let params_get = ""
      // Operacion
      params_get +=
        "&operation=" +
        operation_id?.find(
          element =>
            element.name === property.operations[0].operation_type.toLowerCase()
        )?.id
      // Tipo
      params_get += "&tipo=" + property.type.id
      // Location
      params_get +=
        "&localization-divisions=" +
        property.location.id +
        "&localization-type=division"

      // if (price.type) {
      var price_type = ""
      switch (property.operations[0].prices[0].currency) {
        case "ARS": {
          price_type = "ars"
          break
        }
        default:
          price_type = "usd"
      }

      const priceMin = property.operations[0].prices[0].price
        ? property.operations[0].prices[0].price * 0.8
        : 0
      const priceMax = property.operations[0].prices[0].price
        ? property.operations[0].prices[0].price * 1.2
        : 100000

      params_get +=
        "&currency=" + price_type + "&price=" + priceMin + "-" + priceMax
      // }

      // - Que sea en el misma location
      // - Mismo tipo operación
      // - Tipo de propiedad
      // - Rango de precio + - 20%

      try {
        const res = await axios.get(
          "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/search?cache=0&save=0&limit=10&offset=0" +
            params_get,
          {
            headers: {
              "X-TOKKO-KEY": getState().settings.keys.tokko,
            },
          }
        )
        dispatch({
          type: GET_SIMILAR_PROPERTIES,
          payload: res,
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
export const getPropertyAction = id => async (dispatch, getState) => {
  dispatch({ type: LOADING })
  try {
    const res = await axios.get(
      " https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/" +
        id +
        "?cache=0",
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko,
        },
      }
    )
    dispatch({
      type: GET_PROPERTY,
      payload: res,
    })
  } catch (error) {
    console.log(error)
  }
}
export const getFavoritesAction =
  (id, mediacoreQuery = false, CLIENT_ID = "") =>
  async (dispatch, getState) => {
    dispatch({
      type: LOADING,
    })
    if (
      getState().properties.favorites.find(
        element => element.data.id.toString() === id.toString()
      )
    ) {
      const new_favs = getState().properties.favorites.filter(
        element => element.data.id.toString() !== id.toString()
      )
      dispatch({
        type: DELETE_FAV_PROPERTY,
        payload: new_favs,
      })
    }
    try {
      let res
      if (!mediacoreQuery) {
        res = await axios.get(
          " https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/" +
            id +
            "?cache=0",
          {
            headers: {
              "X-TOKKO-KEY": getState().settings.keys.tokko,
            },
          }
        )
      } else {
        res = await axios.get(
          process.env.GATSBY_APISERVER +
            `/site/propiedades/${id}/ficha/?client=${CLIENT_ID}`
        )
        res.data = adapt_property_data(res.data)
      }
      dispatch({
        type: GET_FAV_PROPERTY,
        payload: res,
      })
    } catch (error) {
      console.log(error)
    }
  }
export const updaterFavoritesAction = () => async (dispatch, getState) => {
  dispatch({ type: UPDATER_FAVORITES })
}

export const clearFavoritesAction = id => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_FAV_PROPERTY,
  })
}
export const changeSelectedAction = id => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_SELECTED,
    payload: id,
  })
}
export const changeOffsetPropertiesAction =
  offset => async (dispatch, getState) => {
    dispatch({
      type: UPDATE_OFFSET,
      payload: offset,
    })
  }
export const deleteFavoriteAction = id => async (dispatch, getState) => {
  dispatch({
    type: DELETE_FAV_PROPERTY,
    payload: id,
  })
}
export const clearPropertiesAction = id => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_PROPERTIES,
  })
}
export const clearPropertyAction = () => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_PROPERTY,
  })
}
export const getFullPropertiesAction = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_FULL_PROPERTIES,
  })
  const filters = getState().properties.filters
  const orders = getState().properties.orders
  const coordinates = getState().properties.coordinates
  var params_get = ""
  var tags_both = []
  var specials = []
  const operation_id = [
    { name: SALE.route, id: 1 },
    { name: RENT.route, id: 2 },
    { name: TEMPORARY.route, id: 3 },
  ]
  const environments = [
    { name: "1 ambiente", id: 1 },
    { name: "2 ambientes", id: 2 },
    { name: "3 ambientes", id: 3 },
    { name: "4 ambientes", id: 4 },
    { name: "5 ambientes", id: 5 },
    { name: "6 ambientes", id: 6 },
    { name: "7 ambientes", id: 7 },
    { name: "8 ambientes", id: 8 },
    { name: "mas de 9 ambientes", id: 9 },
  ]
  if (filters.operation) {
    params_get +=
      "&operation=" +
      operation_id?.find(
        element => element.name === filters.operation.toLowerCase()
      ).id
  }
  if (filters.type.length > 0) {
    params_get += "&tipo=" + filters.type.map(element => element.id)
  }
  if (filters.location.length > 0) {
    params_get +=
      "&localization-divisions=" +
      filters.location.map(element => element.id) +
      "&localization-type=division"
  }
  if (filters.services.length > 0) {
    tags_both.push(filters.services.map(element => element.id))
  }
  if (filters.specials.length > 0) {
    specials.push(filters.specials.map(element => element.id))
    params_get += "&custom_tags=" + specials.map(element => element)
  }
  if (filters.generals.length > 0) {
    tags_both.push(filters.generals.map(element => element.id))
  }
  if (filters.environments_types.length > 0) {
    tags_both.push(filters.environments_types.map(element => element.id))
  }
  if (
    filters.environments_types.length > 0 ||
    filters.generals.length ||
    filters.services.length > 0
  ) {
    params_get += "&tags=" + tags_both
  }
  if (filters.surface.type) {
    var type_surface = ""
    const surfaceMin = filters.surface.surfaceMin
      ? filters.surface.surfaceMin
      : 0
    const surfaceMax = filters.surface.surfaceMax
      ? filters.surface.surfaceMax
      : 999999999
    if (filters.surface.type.toLowerCase() == "cubierta") {
      type_surface = "roofed"
    } else {
      type_surface = filters.surface.type
    }
    params_get +=
      "&surface-type=" +
      type_surface +
      "&surface=" +
      surfaceMin +
      "-" +
      surfaceMax
  }
  if (filters.price.type) {
    var price_type = ""
    switch (filters.price.type) {
      case "pesos": {
        price_type = "ars"
        break
      }
      default:
        price_type = "usd"
    }

    const priceMin = filters.price.priceMin ? filters.price.priceMin : 0
    const priceMax = filters.price.priceMax ? filters.price.priceMax : 10000000

    params_get +=
      "&currency=" + price_type + "&price=" + priceMin + "-" + priceMax
  }
  if (filters.environments.length > 0) {
    var curenvironmets = []
    for (const iterator of filters.environments) {
      curenvironmets.push(
        environments.find(element => element.name === iterator.toLowerCase()).id
      )
    }
    if (curenvironmets[0] == "9") {
      params_get += "&rooms=" + curenvironmets[0] + "-99"
    } else {
      params_get += "&rooms=" + curenvironmets[0]
    }
  }
  if (coordinates?.length > 0) {
    params_get +=
      "&longitude=" +
      coordinates[0] +
      "," +
      coordinates[1] +
      "&latitude=" +
      coordinates[2] +
      "," +
      coordinates[3]
  }
  try {
    const res = await axios.get(
      " https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/search?cache=0&save=1&limit=3000&offset=0&order-by=" +
        orders.order_by +
        "&order=" +
        orders.order +
        "" +
        params_get,
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko,
        },
      }
    )
    dispatch({
      type: GET_FULL_PROPERTIES,
      payload: res,
    })
  } catch (error) {
    console.log(error)
  }
}
export const getFeaturedPropertiesAction = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_FEATURED,
  })
  try {
    const res = await axios.get(
      "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/search?cache=1&save=0&limit=24&offset=0&destacados=1",
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko,
        },
      }
    )
    dispatch({
      type: GET_FEATURED_PROPERTIES,
      payload: res,
    })
  } catch (error) {
    console.log(error)
  }
}

export const setCurrentlyBlueprint = value => async dispatch => {
  dispatch({ type: SET_CURRENTLY_BLUEPRINT, payload: value })
}
export const setCurrentlyImage = value => async dispatch => {
  dispatch({ type: SET_CURRENTLY_IMAGES, payload: value })
}
export const setTogglerImages = () => async dispatch => {
  dispatch({ type: SET_TOGGLER_IMAGES })
}
export const setTogglerVideos = () => async dispatch => {
  dispatch({ type: SET_TOGGLER_VIDEOS })
}
export const setTogglerTours = () => async dispatch => {
  dispatch({ type: SET_TOGGLER_TOURS })
}
export const setTogglerBlueprints = () => async dispatch => {
  dispatch({ type: SET_TOGGLER_BLUEPRINTS })
}

export const setFilterHome =
  ({ operation, type, location, address }) =>
  async (dispatch, getState) => {
    dispatch({
      type: SET_SEARCH_HOME_OPERATION,
      payload: operation,
    })
    dispatch({
      type: SET_SEARCH_HOME_TYPE,
      payload: type,
    })
    dispatch({
      type: SET_SEARCH_HOME_LOCATION,
      payload: location,
    })
    dispatch({
      type: SET_SEARCH_HOME_ADDRESS,
      payload: address,
    })
  }
export const getResumeTotalCount =
  (params, location_id = null) =>
  async (dispatch, getState) => {
    // if(!getState().properties.loading_count){
    dispatch({
      type: LOADING_COUNT,
    })
    const filters = params
    var params_get = ""
    if (filters.operation.toString() === "") {
      return null
    }

    const operation_id = [
      { name: "Tipo de operación", id: "1,2,3" },
      { name: "tipo de operación", id: "1,2,3" },
      { name: "tipo-de-operación", id: "1,2,3" },
      { name: SALE.route, id: 1 },
      { name: RENT.route, id: 2 },
      { name: TEMPORARY.route, id: 3 },
    ]

    if (filters.operation) {
      params_get +=
        "&operation=" +
        operation_id?.find(
          element => element.name === filters.operation.toLowerCase()
        ).id
    }
    if (filters.type.length > 0) {
      params_get += "&tipo=" + filters.type.map(element => element.id)
    }
    if (filters.location.length > 0) {
      params_get +=
        "&localization-divisions=" +
        filters.location.map(element => element.id) +
        "&localization-type=division"
    }

    try {
      const res = await axios.get(
        "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/search?cache=1&save=0&limit=1&offset=0&" +
          "" +
          params_get,
        {
          headers: {
            "X-TOKKO-KEY": getState().settings.keys.tokko,
          },
        }
      )
      if (getState().properties.totalCountAll === 0) {
        dispatch({
          type: GLOBAL_TOTAL_COUNT,
          payload: res.data.meta.total_count,
        })
      }
      dispatch({
        type: GET_TOTALCOUNT,
        payload: res.data.meta.total_count,
      })
    } catch (error) {
      console.log(error)
    }
    // }
  }

export const getPropertiesByAddress =
  (isMediacore = false, CLIENT_ID = 0) =>
  async (dispatch, getState) => {
    let params_get = ""
    const { operation, address, type, location } =
      getState().properties.searchHome
    const types = []
    console.log(isMediacore)
    if (address?.length < 3) {
      return null
    }
    if (!getState().properties.fetching_properties) {
      dispatch({
        type: FETCHING_PROPERTIES,
      })
      const operation_id = [
        { name: "", id: "1,2,3" },
        { name: "Tipo de operación", id: "1,2,3" },
        { name: "tipo de operación", id: "1,2,3" },
        { name: "tipo-de-operación", id: "1,2,3" },
        { name: "venta", id: 1 },
        { name: "alquiler", id: 2 },
        { name: "alquiler temporario", id: 3 },
        { name: "alquiler-temporario", id: 3 },
        { name: SALE.route, id: 1 },
        { name: RENT.route, id: 2 },
        { name: TEMPORARY.route, id: 3 },
      ]
      if (operation) {
        if (isMediacore) {
          params_get += "&operation_type=" + operation.toLowerCase()
        } else {
          params_get +=
            "&operation=" +
            operation_id?.find(
              element => element.name === operation.toLowerCase()
            ).id
        }
      }
      if (location.length > 0) {
        if (isMediacore) {
          params_get +=
            "&neighborhood=" +
            location
              .filter(({ type }) => type === "neighborhood")
              .map(loc => loc.name)
        } else {
          params_get +=
            "&localization-divisions=" +
            location.map(element => element.id) +
            "&localization-type=division"
        }
      }
      if (type) {
        // const total_filters = getState().properties.totalListFilters
        if (type?.length > 0) {
          const total_filters = getState().properties.totalListFilters
          console.log(total_filters)
          for (let i = 0; i < type.length; i++) {
            const id_type =
              type[i].name !== ""
                ? [
                    total_filters.data.objects.property_types.find(
                      element =>
                        element?.type?.toLowerCase() ===
                        type[i].name?.toLowerCase()
                    )?.id,
                  ]
                : []
            types.push(id_type)
          }
          params_get += "&tipo=" + types.join(", ")
        }
        // const id_types = type !== '' && type !== 'all' ? [total_filters.data.objects.property_types.find(element => element?.type?.toLowerCase() === type?.toLowerCase())?.id] : [];
        // if(id_types.length > 0){
        //   params_get+= "&tipo=" + id_types
        // }
      }
      if (address) {
        params_get += (isMediacore ? "&address=" : "&fake_address=") + address
      }
      try {
        let res
        if (isMediacore) {
          res = await axios.get(
            process.env.GATSBY_APISERVER +
              "/site/propiedades/template/?client=" +
              CLIENT_ID +
              "" +
              params_get
          )
        } else {
          res = await axios.get(
            (isMediacore
              ? process.env.GATSBY_APISERVER +
                "/site/propiedades/template/?client=" +
                CLIENT_ID
              : "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/search?cache=1&save=1&limit=24&offset=0&") +
              "" +
              params_get,
            {
              headers: {
                "X-TOKKO-KEY": getState().settings.keys.tokko,
              },
            }
          )
        }

        dispatch({
          type: GET_PROPERTIES_HOME,
          payload: isMediacore
            ? res?.data.map(prop => adapt_property_data(prop))
            : res?.data?.objects,
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
