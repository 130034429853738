import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import { mediacoreApi } from './mediacoreApi'
import { middlewareTokkoApi } from './middlewareTokkoApi'
import { originalTokkoApi } from './originalTokkoApi'
import devDucks from './developmentsDucks'
import propDucks from './propertiesDucks'
import themeDucks from './themeDucks'
import settingsDucks from './settingsDucks'
import noveltiesDucks from './noveltiesDucks'
import actionsDucks from './actionsDucks'
import contactDucks from './contactDucks'

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [mediacoreApi.reducerPath]: mediacoreApi.reducer,
    [middlewareTokkoApi.reducerPath]: middlewareTokkoApi.reducer,
    [originalTokkoApi.reducerPath]: originalTokkoApi.reducer,
    properties: propDucks,
    developments: devDucks,
    theme:themeDucks,
    settings:settingsDucks,
    novelties:noveltiesDucks,
    contact:contactDucks,
    actions:actionsDucks,
  },
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(mediacoreApi.middleware,middlewareTokkoApi.middleware,originalTokkoApi.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)