import axios from "axios"

// constantes
const dataInicial = {
  developments: [],
  developmentsFilters: [],
  development: {},
  unities: {},
  stateCodes: [
    {},
    {},
    {},
    {
      id: 3,
      name: "En pozo",
    },
    {
      id: 4,
      name: "En Construcción",
    },
    {},
    {
      id: 6,
      name: "Construcción Terminada",
    },
  ],
  loading: true,
  loading_unities: false,
  loading_developments: false,
  loading_filter_developments: false,
  loaded_developments: false,
  filtersDevelopment: {
    status: "",
  },
}

// types
const LOADING_DEVELOPMENT = "LOADING_DEVELOPMENT"
const LOADING_DEVELOPMENTS = "LOADING_DEVELOPMENTS"
const LOADING_FILTER_DEVELOPMENTS = "LOADING_FILTER_DEVELOPMENTS"
const LOADING_UNITIES = "LOADING_UNITIES"
const GET_DEVELOPMENT = "GET_DEVELOPMENT"
const UPDATE_FILTERS_DEVELOPMENTS = "UPDATE_FILTERS_DEVELOPMENTS"
const GET_DEVELOPMENTS_FILTERS = "GET_DEVELOPMENTS_FILTERS"
const GET_UNITS_DEVELOPMENT = "GET_UNITS_DEVELOPMENT"
const GET_DEVELOPMENTS = "GET_DEVELOPMENTS"
const CLEAN_DEVELOPMENT = "CLEAN_DEVELOPMENT"

// reducer
export default function developmentReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING_DEVELOPMENT: {
      return { ...state, loading: true }
    }
    case LOADING_DEVELOPMENTS: {
      return { ...state, loading_developments: true }
    }
    case LOADING_FILTER_DEVELOPMENTS: {
      return { ...state, loading_filter_developments: true }
    }
    case LOADING_UNITIES: {
      return { ...state, loading_unities: true.payload }
    }
    case GET_DEVELOPMENT: {
      return { ...state, development: action.payload, loading: false }
    }
    case GET_DEVELOPMENTS: {
      return {
        ...state,
        developments: action.payload,
        loading: false,
        loading_developments: false,
        loaded_developments: true,
      }
    }
    case GET_DEVELOPMENTS_FILTERS: {
      return { ...state, developmentsFilters: action.payload, loading: false }
    }
    case GET_UNITS_DEVELOPMENT: {
      return { ...state, unities: action.payload.data.objects }
    }
    case CLEAN_DEVELOPMENT: {
      return { ...state, development: action.payload }
    }
    case UPDATE_FILTERS_DEVELOPMENTS: {
      return { ...state, filtersDevelopment: action.payload }
    }
    default:
      return state
  }
}

// actions
export const getDevelopmentAction = id => async (dispatch, getState) => {
  dispatch({ type: LOADING_DEVELOPMENT })
  try {
    const res = await axios.get(
      "https://api-mh.mediahaus.com.ar/api/v1/tokko/developments/" +
        id +
        "?cache=false",
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko,
        },
      }
    )
    dispatch({
      type: GET_DEVELOPMENT,
      payload: res.data,
    })
  } catch (error) {
    console.log(error)
  }
}
export const getUnitsDevelopmentAction = id => async (dispatch, getState) => {
  try {
    // const res = await axios.get('https://www.tokkobroker.com/api/v1/property/?development__id=' + id +'&lang=es_ar&key=' + getState().settings.keys.tokko +'&format=json&limit=1000')
    const res = await axios.get(
      "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/search?development_id=" +
        id +
        "&limit=1000&cache=0&save=0",
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko,
        },
      }
    )
    dispatch({
      type: GET_UNITS_DEVELOPMENT,
      payload: res,
    })
  } catch (error) {
    console.log(error)
  }
}
export const getDevelopmentsAction = () => async (dispatch, getState) => {
  if (
    getState().developments.loaded_developments ||
    getState().developments.loading_developments
  ) {
    return null
  }
  dispatch({ type: LOADING_DEVELOPMENTS })
  try {
    const res = await axios.get(
      "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/developments/search?cache=0",
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko,
        },
      }
    )
    dispatch({
      type: GET_DEVELOPMENTS,
      payload: res,
    })
  } catch (error) {
    console.log(error)
  }
  // }
}
export const cleanDevelopmentAction = () => (dispatch, getState) => {
  dispatch({
    type: CLEAN_DEVELOPMENT,
    payload: {},
  })
}
export const getDevelopmentsFilterAction = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_FILTER_DEVELOPMENTS })

  var status = ""
  if (getState().developments.filtersDevelopment.status) {
    status =
      "&construction_status=" +
      getState().developments.filtersDevelopment.status
  }

  try {
    const res = await axios.get(
      "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/developments/search?cache=0" +
        status,
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko,
        },
      }
    )
    dispatch({
      type: GET_DEVELOPMENTS_FILTERS,
      payload: res,
    })
  } catch (error) {
    console.log(error)
  }
}
export const updateFiltersDevelopment = status => (dispatch, getState) => {
  const res = {
    status,
  }
  dispatch({
    type: UPDATE_FILTERS_DEVELOPMENTS,
    payload: res,
  })
}
